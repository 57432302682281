import MicroModal from 'micromodal';

const initVideoModal = () => {
  // We need to keep track of all the callbacks that we set on the video when opening the modal window in order
  // to remove them on closing and avoid memory leaks.
  const buildMicroModalConfig = () => {
    const videoOnEndedCallback = {};

    const onShow = (modal, _, event) => {
      event.preventDefault();
      const closeModal = () => modal.querySelector('[data-modal-close]').click();

      const video = modal.querySelector('video');

      /**
       * Close modal when video ends
       * - Register callback
       * - Bind callback
       */
      videoOnEndedCallback[modal.id] = () => closeModal();
      video.addEventListener('ended', videoOnEndedCallback[modal.id]);

      /**
       * Play video
       */
      video.play();
    };

    const onClose = (modal, _, event) => {
      event.preventDefault();

      const video = modal.querySelector('video');

      /**
       * Unbind and unregister callback
       */
      video.removeEventListener('ended', videoOnEndedCallback[modal.id]);
      delete videoOnEndedCallback[modal.id];

      /**
       * Stop video - pause and seek to start
       */
      video.pause();
      video.currentTime = 0;
    };

    return {
      onShow,
      onClose,
      openTrigger: 'data-modal-open',
      closeTrigger: 'data-modal-close',
      openClass: '-open',
      disableScroll: true,
      disableFocus: true,
      awaitOpenAnimation: false,
      awaitCloseAnimation: false,
      debugMode: false,
    };
  };

  MicroModal.init(buildMicroModalConfig());
};

export default initVideoModal;
